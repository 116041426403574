import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../hooks/useRedux';
import styles from './OrdersList.module.scss';
import Loader from '../../../components/Loader';
import { DispatchType } from '../../../reducers';
import { getOrdersAction, getOrdersListAction } from '../../../actions/orders';
import { IOrder } from '../../../types/order.types';
import OrderCard from '../../../components/OrderCard/OrderCard';
import OrderRow from '../../../components/OrderRow/OrderRow';
import { DatePicker, StyledSelect } from '../../../lib/HooksFormFields';
import { IOption } from '../../../lib/HooksFormFields/StyledSelect';
import EventCard from '../../../components/EventCard/EventCard';
import { SET_ORDERS_FILTERS } from '../../../actions/actions';

interface IFilters {
  status: IOption,
  sort: IOption,
  event: IOption,
  structure: IOption,
  dates: Date[],
}

export default function OrdersList({ archived = false }:{ archived?: boolean | null}) {
  const { user } = useAppSelector((state: any) => state.authReducer);
  const {
    ordersList, isLoading, lists, filters,
  } = useAppSelector((state: any) => state.orderReducer);
  const [orders, setOrders] = useState([]);
  const [archivedOrders, setArchivedOrders] = useState([]);
  const allStatus = { value: 'all', label: 'Tous les status' };
  const allEvents = { value: 'all', label: 'Tous les events' };
  const allStructures = { value: 'all', label: 'Toutes les structures' };
  const sortOptions = [{ label: 'Date croissante', value: 'date-desc' }, { label: 'Date décroissante', value: 'date-asc' }];
  const activesOrders = ordersList?.filter((o: IOrder) => o.status !== 'archived');

  const getStatusList = () => {
    if (!lists?.status) return [];
    let status = [...lists.status];
    if (user.role === 'Owner') {
      status = [...lists.status.filter((s: IOption) => s.value !== 'new')];
    }
    return [allStatus, ...status];
  };

  const dates = ordersList?.map((d: IOrder) => d)
    .sort((a: IOrder, b: IOrder) => new Date(a.deliveryDate).getTime() - new Date(b.deliveryDate).getTime())
    .map((d: IOrder) => new Date(d.deliveryDate));

  const defaultValues = filters || {
    status: allStatus,
    sort: sortOptions[0],
    structure: allStructures,
    event: allEvents,
    dates: [new Date(), new Date()],
    archived,
  };
  const {
    control,
    watch,
    reset,
  } = useForm<IFilters>({
    defaultValues,
  });
  const dispatch: DispatchType = useAppDispatch();
  const status = watch('status')?.value;
  const structure = watch('structure')?.value;
  const sort = watch('sort')?.value;
  const event = watch('event')?.value;
  const [start, end] = watch('dates');

  useEffect(() => {
    getOrdersListAction(dispatch);
    getOrdersAction(dispatch);
  }, []);

  useEffect(() => {
    if (ordersList.length) {
      if (!filters?.dates) {
        reset({ ...watch(), dates: [new Date(dates[0]), new Date(dates[dates.length - 1])] });
      }
    }
  }, [ordersList, status, event]);

  useEffect(() => () => {
    dispatch({
      type: SET_ORDERS_FILTERS,
      payload: { ...watch() },
    });
  }, []);

  useEffect(() => {
    if (status && sort && end && start) {
      const filtered = ordersList?.filter((o: IOrder) => {
        const date = new Date(o.deliveryDate).getTime();
        if (date < start.getTime() || date > end.getTime()) {
          return false;
        }
        return true;
      })?.filter((o: IOrder) => {
        if (event === 'all') {
          return o;
        }
        return o.eventType === event;
      })?.filter((o: IOrder) => {
        if (archived) {
          return o.status === 'archived';
        }
        if (status === 'all') {
          return o.status !== 'archived';
        }
        return o.status === status;
      })?.filter((o: IOrder) => {
        if (structure === 'all') {
          return o;
        }
        if (o.structure._id === structure) {
          return o;
        }
        return null;
      });
      const sorted = filtered?.sort((a: IOrder, b: IOrder) => {
        const aDate = new Date(a.deliveryDate).getTime();
        const bDate = new Date(b.deliveryDate).getTime();
        if (sort === 'date-desc') {
          return aDate - bDate;
        }
        return bDate - aDate;
      });
      setOrders(sorted);
      const archivedList = ordersList?.filter((o: IOrder) => {
        const date = new Date(o.deliveryDate).getTime();
        if (date >= start.getTime() && date <= end.getTime()) {
          return true;
        }
        return false;
      })
        ?.filter((o: IOrder) => o.status === 'archived')
        .filter((o: IOrder) => {
          if (event === 'all') {
            return o;
          }
          return o.eventType === event;
        });
      const archivedSorted = archivedList?.sort((a: IOrder, b: IOrder) => {
        const aDate = new Date(a.deliveryDate).getTime();
        const bDate = new Date(b.deliveryDate).getTime();
        if (sort === 'date-desc') {
          return aDate - bDate;
        }
        return bDate - aDate;
      });
      setArchivedOrders(archivedSorted);
    }
  }, [status, sort, event, archived, structure, ordersList, end, start]);

  return (
    <div className={styles.container}>
      <header>
        {user?.role === 'Member'
          && (
          <Link
            to="/customer/order/form?step=1"
            className={`${styles.btn} ${styles.primary}`}
          >
            Faire une demande
          </Link>
          )}
      </header>
      {isLoading
        ? (
          <div className={styles.loader}>
            <Loader />
          </div>
        )
        : (
          <div className={styles.content}>
            {user?.role === 'Member'
              && (
              <div>
                {!archived
                  ? (
                    <>
                      <h2>Mes demandes</h2>
                      <div className={styles.grid}>
                        <EventCard />
                        {activesOrders.map((o: IOrder) => <OrderCard key={o._id} order={o} />)}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className={styles.header}>
                        <h2>Archives</h2>
                        <div className={styles.filters}>
                          <div className={styles['container-field']}>
                            <StyledSelect
                              name="event"
                              control={control}
                              rules={{ }}
                              className="secondary"
                              placeholder="Séléctionner..."
                              options={lists?.events ? [allEvents, ...lists.events] : []}
                            />
                          </div>
                          <div className={styles['container-field']}>
                            <StyledSelect
                              name="sort"
                              control={control}
                              className="secondary"
                              rules={{ }}
                              placeholder="Séléctionner..."
                              options={sortOptions}
                            />
                          </div>
                          <div className={styles['container-field']}>
                            <DatePicker
                              name="dates"
                              control={control}
                              startDate={new Date(start)}
                              endDate={new Date(end)}
                              selectsRange
                            />
                          </div>
                        </div>
                      </div>
                      <div className={styles.list}>
                        {archivedOrders?.map((o: IOrder) => (
                          <OrderRow
                            key={o._id}
                            order={o}
                            role={user.role}
                          />
                        ))}
                      </div>
                    </>
                  )}
              </div>
              )}
            {(user?.role !== 'Member')
              && (
              <div>
                <div className={styles.header}>
                  <h2>{!archived ? 'Mes demandes' : 'Mes archives'}</h2>
                  <div className={styles.filters}>
                    {!archived
                      && (
                        <div className={styles['container-field']}>
                          <StyledSelect
                            name="status"
                            control={control}
                            rules={{ }}
                            className="secondary"
                            placeholder="Séléctionner..."
                            options={getStatusList()}
                          />
                        </div>
                      )}
                    <div className={styles['container-field']}>
                      <StyledSelect
                        name="event"
                        control={control}
                        rules={{ }}
                        className="secondary"
                        placeholder="Séléctionner..."
                        options={lists?.events ? [allEvents, ...lists.events] : []}
                      />
                    </div>
                    <div className={styles['container-field']}>
                      <StyledSelect
                        name="structure"
                        control={control}
                        rules={{ }}
                        className="secondary"
                        placeholder="Séléctionner..."
                        options={lists?.allStructures ? [allStructures, ...lists.allStructures] : []}
                      />
                    </div>
                    <div className={styles['container-field']}>
                      <StyledSelect
                        name="sort"
                        control={control}
                        className="secondary"
                        rules={{ }}
                        placeholder="Séléctionner..."
                        options={sortOptions}
                      />
                    </div>
                    <div className={styles['container-field']}>
                      <DatePicker
                        name="dates"
                        control={control}
                        startDate={new Date(start)}
                        endDate={new Date(end)}
                        selectsRange
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.list}>
                  {orders?.filter((o:IOrder) => o.status !== 'draft').map((o: IOrder) => (
                    <OrderRow
                      key={o._id}
                      order={o}
                      role={user.role}
                    />
                  ))}
                </div>
              </div>
              )}
          </div>
        )}
    </div>
  );
}
